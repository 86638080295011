
























































































































































































































































































































































































































































































































/* eslint-disable */
import { api_login, sign_up, api_service } from "@/api";
import { ElForm } from "element-ui/types/form";
import { Vue, Component, Watch } from "vue-property-decorator";
import { mapObjIndexed } from "ramda";
import PhoneSend from "../components/phoneSend.vue";
import Inner from "@/components/inner/index.vue";
import { toTop } from "@/util/other";
import { OtherModule } from "@/store/other";
@Component({
  components: {
    PhoneSend,
    Inner,
  },
})
export default class extends Vue {
  type = 0;

  older = {
    username: "",
    phone: "",
  };

  btn_loadding = {
    mail: false,
    check_sms: false,
  };

  validatePass_8(rules: any, value: any, callback: any) {
    const reg =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    if (reg.test(value)) {
      // (this.$refs["form"] as ElForm).validateField("again_pass");
      callback();
    } else {
      callback(new Error("영문, 숫자, 특수문자(! 또는 @) 포함 8자 이상 입력"));
    }
  }

  validatePass(rules: any, value: any, callback: any) {
    if (this.info.password !== this.info.again_pass) {
      callback(new Error("비밀번호와 비밀번호 확인이 일치하지 않습니다."));
    } else {
      callback();
    }
  }

  validateUsername(rules: any, value: any, callback: any) {
    const reg=/^[0-9a-zA-Z]{6,12}$/;
    if(!reg.test(value)) {
      callback(new Error("6-12자 이내 영문, 숫자 입력"));
    }else if(this.older.username !== value){
      callback(new Error("아이디 중복확인을 진행해주세요."));
    }else {
      callback()
    }
  }

  async check_username() {
    this.btn_loadding.mail = true;
    (this.$refs["form"] as ElForm).validateField(
      "username",
      async (err_str) => {
        if (err_str === "아이디 중복확인을 진행해주세요." || err_str === "") {
          const data = await api_login
            .check_username({
              username: this.info.username,
            })
            .finally(() => {
              this.btn_loadding.mail = false;
            });
          this.older.username = this.info.username;
          await (this.$refs["form"] as ElForm).validateField("username");
          this.$message.success("중복된 아이디가 없습니다.");
        } else {
          this.$message.error(err_str);
          this.btn_loadding.mail = false;
        }
      }
    );
  }

  validateEmail(rule: any, value: any, callback: any) {
    if (value === "") {
      callback(new Error("이메일을 입력해 주세요."));
    } else if (
      // !value.match(/^[a-zA-Z0-9_-|.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/) ||
      !value.match(
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      )
    ) {
      callback(new Error("정확한 이메일 주소를 입력해 주세요."));
    } else {
      callback();
    }
  }

  validatePhone(rule: any, value: string, callback: any) {
    const reg = /^(\d){3}-(\d){4}-(\d){4}$/
    if (!reg.test(value)) {
      callback(new Error("정확한 휴대폰번호를 입력해주세요."));
    } else if (this.older.phone !== value) {
      callback(new Error("휴대폰 인증을 진행해 주세요."));
    } else {
      callback();
    }
  }
  changePhone(res:any){
    if(res.length>8){
      if(res.slice(3,4) === '-' && res.slice(8,9) === '-'){
        this.info.phone = res
      }else if(res.slice(3,4) === '-'){
        this.info.phone = res.slice(0,8) + '-' + res.slice(8)
      }else{
        this.info.phone = res.slice(0,3) + '-' + res.slice(3,7) + '-' + res.slice(7)
      }
    }else if(res.length>3){
      if(res.slice(3,4) === '-'){
        this.info.phone = res
      }else{
        this.info.phone = res.slice(0,3) + '-' + res.slice(3)
      }
    }else{
      this.info.phone = res
    }
  }

  async check_tel_pass() {
    (this.$refs["form"] as ElForm).validateField("phone", async (err_msg) => {
      if (err_msg === "휴대폰 인증을 진행해 주세요." || err_msg === "") {
        this.btn_loadding.check_sms = true;
        await api_login
          .check_sms({
            phone: this.info.phone,
            code: this.info.code,
          })
          .finally(() => {
            this.btn_loadding.check_sms = false;
          });
        this.$message.success("인증 완료 되었습니다.");
        this.older.phone = this.info.phone;
        (this.$refs["form"] as ElForm).validateField("phone");
      } else {
        this.$message.error(err_msg);
        this.btn_loadding.check_sms = false;
      }
    });
  }

  rules = {
    username: [
      {
        required: true,
        validator: this.validateUsername,
        trigger: "change",
      },
    ],
    password: [
      {
        validator: this.validatePass_8,
        required: true,
        trigger: ["change"],
      },
    ],
    again_pass: [
      {
        required: true,
        validator: this.validatePass,
        trigger: ["blur"],
      },
    ],
    real_name: [
      { required: true, message: "이름을 입력해 주세요." },
      { max: 10, message: "10자 이내로 작성해 주세요." },
    ],
    gender: [{ required: true }],
    birth: [{ required: true, message: "태어난 년도를 선택해 주세요." }],
    phone: [
      {
        required: true,
        trigger: ["change"],
        validator: this.validatePhone,
      },
    ],
    email: [{
        required: true,
        validator: this.validateEmail,
        trigger: "change",
      },],
    address: [{ required: true, message: "주소를 검색해 주세요." }],
    address_code: [{ required: true, message: "주소를 검색해 주세요." }],
    address_detail: [{ required: true, message: "주소를 검색해 주세요." }],
    // favorite_category: [
    //   { required: true, message: "1개 이상 분야를 선택해 주세요." },
    // ],
    is_email: [{ required: true }],
    is_sms: [{ required: true }],
    all: [{ required: true }]
  };
  info = {
    username: "",
    password: "",
    again_pass: "",
    real_name: "",
    gender: "",
    birth: "",
    phone: "",
    code: "",
    email: "",
    address: "",
    address_code: "",
    address_detail: "",
    favorite_category: [0],
    is_email: true,
    is_sms: true,
    all:false
  };
  all_1 = true;
  all_2 = true;
  is = true;

  @Watch("all_1", { immediate: true })
  @Watch("all_2", { immediate: true })
  watch_all() {
    if (this.all_1 === true && this.all_2 === true) {
      this.info.all = true;
    } else {
      this.info.all = false;
    }
  }
  all_change(type: boolean) {
    this.all_2 = type;
    this.all_1 = type;
  }

  @Watch("info.is_email", { immediate: true })
  @Watch("info.is_sms", { immediate: true })
  watch_is() {
    if (this.info.is_email === true && this.info.is_sms === true) {
      this.is = true;
    } else {
      this.is = false;
    }
  }
  is_change(type: boolean) {
    this.info.is_email = type;
    this.info.is_sms = type;
  }

  key = false;

  async submit() {
    if (this.info.gender === "") {
      return this.$message.error("성별을 선택해 주세요.");
    }
    try {
      await (this.$refs["form"] as ElForm).validate();
      if (this.info.all === false) {
        return this.$message.error("이용약관을 동의해 주세요.");
      }
      const info = mapObjIndexed((v: any, k: any) => {
        if (typeof v === "boolean") {
          // return Boolean(v)
          return v === true ? 1 : 0;
        } else {
          return v;
        }
      })({ ...this.info });
      this._loading = true;
      await api_login.signup(info as any as sign_up).finally(() => {
        this._loading = false;
      });
      this.type = 1;
    } catch (e) {
      this.$message.error(
        "입력하신 정보가 정확하지 않습니다. 다시 한번 확인해 주세요."
      );
      toTop();
    }
  }

  searchAds() {
    this.key = true;
    setTimeout(() => {
      new daum.Postcode({
        width: "100%",
        onComplete: (data: any) => {
          this.info.address_code = data.zonecode;
          if (data.userSelectedType === "J") {
            this.info.address = data.jibunAddress;
          }
          if (data.userSelectedType === "R") {
            this.info.address = data.roadAddress;
          }
          this.key = false;
          (this.$refs["address_detail"] as HTMLInputElement).focus();
        },
        onclose: () => {
          this.key = false;
        },
      }).embed(this.$refs["daumBox"]);
    }, 100);
  }

  key_1 = false;
  key_2 = false;

  content = {
    use: "",
    privacy: "",
  };

  async get_content() {
    const [use, privacy] = await Promise.all([
      api_service.get_use(),
      api_service.get_privacy(),
    ]);
    this.content = {
      use: use.content,
      privacy: privacy.content,
    };
  }

  async created() {
    this.get_content();
  }

  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
